/* eslint-disable no-unused-vars */
import { useEffect, useState } from "react";
import { SITE_NAME } from "Helper/constant"
import { Helmet } from "react-helmet";
import { Text, Button, Input, SelectBox, Img  } from "../../components";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { API_ROUTES } from "Helper/ApiRoutes";
import GlobalService from "Helper/GlobalService";
import encodeDecodeUri from 'Helper/crypto/encodeDecodeUri';
import ArrowDownIcon from "assets/images/img_arrowdown.svg"

const protocols = [
	{ label: 'File Transfet Protocol', value: 'ftp' },
	{ label: 'SSH File Transfet Protocol', value: 'sftp' },
	{ label: 'Decentralized Cloud Storage', value: 'storj' },
];

const logonType = [
	{ label: 'Anonymous', value: 'anonymous' },
	{ label: 'Normal', value: 'normal' },
	{ label: 'Ask for password', value: 'ask_for_password' },
	{ label: 'Interactive', value: 'interactive' },
	{ label: 'Key file', value: 'key_file' },
];

export default function AddServerCommand() {

  const { id } = useParams();
  const navigation = useNavigate();
  const [serverProtocol, setServerProtocol] = useState({ label: 'File Transfet Protocol', value: 'ftp' });
  const [serverType, setServerType] = useState({ label: 'normal', value: 'Normal' });
  const [serverName, setServerName] = useState('');
  const [serverHost, setServerHost] = useState('');
  const [serverPort, setServerPort] = useState('');
  const [serverUsername, setServerUsername] = useState('');
  const [serverPassword, setServerPassword] = useState('');
  const [serverCommand, setServerCommand] = useState('');

	const [serverNameError, setServerNameError] = useState('');
  const [serverHostError, setServerHostError] = useState('');
  const [serverPortError, setServerPortError] = useState('');
  const [serverUsernameError, setServerUsernameError] = useState('');
  const [serverPasswordError, setServerPasswordError] = useState('');
  const [serverCommandError, setServerCommandError] = useState('');

	const getDetail = async (id) => {
    const response = await GlobalService(API_ROUTES.SERVER.GET_DETAILS(id));
    if (response.ack === 1) {
			setServerProtocol(protocols.find((option) => option.value === response.data.server_protocol));
			setServerType(logonType.find((option) => option.value === response.data.server_type));
			setServerName(response.data.server_name);
			setServerHost(response.data.server_host);
			setServerPort(response.data.server_port);
			setServerUsername(response.data.server_username);
			setServerCommand(response.data.server_command);
    }
  };

  const handleSave = async (e) => {
    e.preventDefault();
    let isValid = true;
		if (serverName.trim() === '') {
			setServerNameError('Server name is required');
			isValid = false;
		}
		if (serverHost.trim() === '') {
			setServerHostError('Server host is required');
			isValid = false;
		}
		if (serverUsername.trim() === '') {
			setServerUsernameError('Server username is required');
			isValid = false;
		}

		if (isValid) {
			const data = {
				server_name: serverName,
				server_protocol: serverProtocol.value,
				server_host: serverHost,
				server_port: serverPort,
				server_type: serverType.value,
				server_username: serverUsername,
				server_password: encodeDecodeUri(serverPassword),
				server_command: serverCommand,
			};
			const response = await GlobalService(API_ROUTES.SERVER.ADD, data, 'post');
      if (response.ack === 1) {
        toast.success(response.message)
        navigation('/server-credential')
      } else {
				if (response.errors) {
					for (const [key, value] of Object.entries(response.errors)) {
						toast.error(value[0])
					}
				} else {
					toast.error(response.message)
				}
			}
		}
  }

  const handleUpdate = async (e) => {
    e.preventDefault();
    let isValid = true;
		if (serverName.trim() === '') {
			setServerNameError('Server name is required');
			isValid = false;
		}
		if (serverHost.trim() === '') {
			setServerHostError('Server host is required');
			isValid = false;
		}
		if (serverUsername.trim() === '') {
			setServerUsernameError('Server username is required');
			isValid = false;
		}
		if (isValid) {
			let data = {
				server_name: serverName,
				server_protocol: serverProtocol.value,
				server_host: serverHost,
				server_port: serverPort,
				server_type: serverType.value,
				server_username: serverUsername,
				server_command: serverCommand,
			};
			if (serverPassword.trim() !== '') {
				data.server_password = encodeDecodeUri(serverPassword);
			}
			const response = await GlobalService(API_ROUTES.SERVER.UPDATE(id), data, 'PUT');
      if (response.ack === 1) {
        toast.success(response.message)
        navigation('/server-credential')
      } else {
				if (response.errors) {
					for (const [key, value] of Object.entries(response.errors)) {
						toast.error(value[0])
					}
				} else {
					toast.error(response.message)
				}
			}
		}

  }

  useEffect(() => {
		if (id) {
			getDetail(id);
		}
  }, [id])

  return (
    <>
      <Helmet>
      <title>{SITE_NAME} | {id?'Update':'Add'} Server Command</title>
        <meta name="description" content="Web site created using create-react-app" />
      </Helmet>
      <div className="flex flex-col items-start justify-start w-[94%] md:w-full gap-[31px]">
        <div className="flex justify-between gap-[7px] w-full">
          <Text size="xl" as="p">Server Command</Text>
        </div>
          <form onSubmit={(e) => {
            if (id) {
              handleUpdate(e);
            } else {
              handleSave(e);
            }
          }} className="flex flex-row flex-col w-full gap-[30px]">
            <div className="flex flex-col items-start justify-center w-full gap-[26px] p-5 bg-white-A700 shadow-md rounded-[15px]">
              <Text size="lg" as="p" className="mt-1.5 !font-medium">
							{id?'Update':'Add'} Server Command
              </Text>
              <div className="flex flex-col items-start justify-start w-full mb-1 gap-[25px]">
                <div className="flex flex-row justify-start w-full">
                  <div className="flex flex-col items-center justify-start w-full gap-[18px]">
										<div className="flex flex-row md:flex-col justify-start items-start w-full gap-[30px] md:gap-5">
                      <div className="flex flex-col items-start justify-start w-[49%] gap-2">
                        <Text size="lg" as="p"> Server Name *</Text>
                        <Input
                          color="gray_50_01" size="sm" type="text" name="name" placeholder="Server Name *"
                          className="w-full sm:w-full border-gray-200 rounded-[5px]"
                          value={serverName} onChange={(e) => {
														setServerName(e.target.value);
														setServerNameError('');
													}}
                        />
                        <Text size="sm" as="p" className="error-text"> {serverNameError} </Text>
                      </div>
											<div className="flex flex-col items-start justify-start w-[49%] md:w-full gap-1.5">
                        <Text size="lg" as="p" className="!text-blue_gray-400">Protocol</Text>
                        <SelectBox
                          indicator={<Img src={ArrowDownIcon} alt="arrow_down" />}
                          color="gray_50_01" size="sm" value={serverProtocol} placeholder="Select" options={protocols}
                          onChange={(value) => {
                            setServerProtocol(value)
                          }}
                          className="w-full gap-px border-gray-200 border border-solid rounded-[5px]"
                        />
                      </div>
                    </div>
										<div className="flex flex-row md:flex-col justify-start items-start w-full gap-[30px] md:gap-5">
											<div className="flex flex-col items-start justify-start w-[49%] gap-2">
                        <Text size="lg" as="p"> Server Host *</Text>
                        <Input
                          color="gray_50_01" size="sm" type="text" name="name" placeholder="Server Host *"
                          className="w-full sm:w-full border-gray-200 rounded-[5px]"
                          value={serverHost} onChange={(e) => {
														setServerHost(e.target.value);
														setServerHostError('');
													}}
                        />
                        <Text size="sm" as="p" className="error-text"> {serverHostError} </Text>
                      </div>
											<div className="flex flex-col items-start justify-start w-[49%] gap-2">
                        <Text size="lg" as="p"> Server Port</Text>
                        <Input
                          color="gray_50_01" size="sm" type="text" name="name" placeholder="Server Port"
                          className="w-full sm:w-full border-gray-200 rounded-[5px]"
                          value={serverPort} onChange={(e) => {
														setServerPort(e.target.value);
														setServerPortError('');
													}}
                        />
                        <Text size="sm" as="p" className="error-text"> {serverPortError} </Text>
                      </div>
										</div>
										<div className="flex flex-row md:flex-col justify-start items-start w-full gap-[30px] md:gap-5">
											<div className="flex flex-col items-start justify-start w-[49%] md:w-full gap-1.5">
                        <Text size="lg" as="p" className="!text-blue_gray-400">Type</Text>
                        <SelectBox
                          indicator={<Img src={ArrowDownIcon} alt="arrow_down" />}
                          color="gray_50_01" size="sm" value={serverType} placeholder="Select" options={logonType}
                          onChange={(value) => {
                            setServerType(value)
                          }}
                          className="w-full gap-px border-gray-200 border border-solid rounded-[5px]"
                        />
                      </div>
											<div className="flex flex-col items-start justify-start w-[49%] gap-2">
                        <Text size="lg" as="p"> Server Username *</Text>
                        <Input
                          color="gray_50_01" size="sm" type="text" name="name" placeholder="Server Username *"
                          className="w-full sm:w-full border-gray-200 rounded-[5px]"
                          value={serverUsername} onChange={(e) => {
														setServerUsername(e.target.value);
														setServerUsernameError('');
													}}
                        />
                        <Text size="sm" as="p" className="error-text"> {serverUsernameError} </Text>
                      </div>
										</div>
										<div className="flex flex-row md:flex-col justify-start items-start w-full gap-[30px] md:gap-5">
											<div className="flex flex-col items-start justify-start w-[49%] gap-2">
                        <Text size="lg" as="p"> Server Password</Text>
                        <Input
                          color="gray_50_01" size="sm" type="password" name="name" placeholder="Server Password"
                          className="w-full sm:w-full border-gray-200 rounded-[5px]"
                          value={serverPassword} onChange={(e) => {
														setServerPassword(e.target.value);
														setServerPasswordError('');
													}}
                        />
                        <Text size="sm" as="p" className="error-text"> {serverPasswordError} </Text>
                      </div>
											<div className="flex flex-col items-start justify-start w-[49%] gap-2">
                        <Text size="lg" as="p"> Server Command</Text>
                        <Input
                          color="gray_50_01" size="sm" type="text" name="name" placeholder="Server Command"
                          className="w-full sm:w-full border-gray-200 rounded-[5px]"
                          value={serverCommand} onChange={(e) => {
														setServerCommand(e.target.value);
														setServerCommandError('');
													}}
                        />
                        <Text size="sm" as="p" className="error-text"> {serverCommandError} </Text>
                      </div>
										</div>
                  </div>
                </div>
                <div className="flex flex-row justify-start gap-[21px]">
                  <Button size="md" shape="round" className="sm:px-5 font-medium min-w-[112px]" type='submit'>
                    Submit
                  </Button>
                  <Button
										size="md" variant="outline" shape="round" className="sm:px-5 font-medium min-w-[112px]" type='button'
										onClick={() => navigation('/server-credential')}
									> Cancel </Button>
                </div>
              </div>
            </div>
        </form>
      </div>
    </>
  );
}
