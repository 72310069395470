import { useEffect } from "react";
import { getLocalStorage, LOCAL_STORAGE_KEYS } from '../Helper/crypto/LocalStorage';
import { Outlet, useNavigate } from "react-router-dom";
import { Text, Img } from "../components";
import { SubMenu, MenuItem, Menu, Sidebar } from "react-pro-sidebar";
import Header from "../components/Header";
import GlobalService from "Helper/GlobalService";
import {API_ROUTES} from "Helper/ApiRoutes";
import DashboardIcon from "assets/images/dashboard-icon.svg"
import ProjectIcon from "assets/images/project-icon.svg"
import GithubIcon from "assets/images/github-icon.svg"
import ServerCredsIcon from "assets/images/server-creds-icon.svg"
import ServerIcon from "assets/images/server-icon.svg"
import LogoutIcon from "assets/images/logout.svg"
import ProjectLogo from "assets/images/img_group_18298.svg"

export default function AppLayout() {

  const navigation = useNavigate();
  const menus = [
    {
      link: '/',
      title: 'Dashboard',
      icon: DashboardIcon
    },
    {
      link: '/project',
      title: 'Projects',
      icon: ProjectIcon
    },
    {
      link: '/github-credentials',
      title: 'Github Credentials',
      icon: GithubIcon
    },
    {
      link: '/server-credential',
      title: 'Server Credentials',
      icon: ServerCredsIcon
    },
    {
      link: '/commands',
      title: 'Commands',
      icon: ServerIcon
    },
    // {
    //   title: 'Github Credentials',
    //   icon: 'images/img_circlesfour.svg',
    //   submenus: [
    //     {
    //       title: 'Submenu list',
    //       icon: 'images/img_circlesfour.svg',
    //       link: '/'
    //     }
    //   ]
    // }
  ];

  const logout = async () => {
    const response = await GlobalService(API_ROUTES.AUTH.LOGOUT, null, 'delete');
    if (response.ack === 1) {
      localStorage.clear();
      navigation('/login')
    } else {

    }
  };

  useEffect(() => {
    if (!getLocalStorage(LOCAL_STORAGE_KEYS.AUTH_TOKEN)) {
      navigation('/login');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <div className="flex flex-row md:flex-col justify-center items-start w-full bg-gray-50">
        <Sidebar width="252px !important" className="h-screen top-0 bg-white-A700 shadow-sm !sticky overflow-auto">
          <Img src={ProjectLogo} alt="image" className="h-[30px] mt-6 ml-7 mr-[97px] md:mr-5 sm:mx-5" />
          <Menu
            menuItemStyles={{
              button: {
                padding: "10px 10px 10px 28px", width: "100%", color: "#8c8787", fontWeight: 400, fontSize: "16px", gap: "12px",
                [`&:hover, &.ps-active`]: { color: "#438ffe", fontWeight: "500 !important", backgroundColor: "#edf5ff !important", },
              },
            }}
            renderExpandIcon={() => <Img src="images/img_arrow_right.svg" alt="arrowright_one" className="h-[24px] w-[24px] cursor-pointer" />}
            className="flex flex-col items-center justify-start w-full mt-[65px]"
          >
          {menus.map((menu, index) => menu?.submenus ? 
            <SubMenu
              label={
                <div className="flex flex-row justify-start items-center gap-3">
                  <Img src={menu.icon} alt="image_two" className="h-[20px] w-[20px]" />
                  <Text size="lg" as="p"> {menu.title} </Text>
                </div>
              }
              key={`menu-${index}`}
            >
              {menu?.submenus.map((submenu, indexs) => <MenuItem
                icon={<Img src={submenu.icon} alt="image_one" className="h-[20px] w-[20px]" />}
                onClick={() => navigation(submenu.link)}
                key={`sub-menu-${index}-${indexs}`}
              >{submenu.title}</MenuItem>)}

            </SubMenu>:
            <MenuItem
              icon={<Img src={menu.icon} alt="image_one" className="h-[20px] w-[20px]" />}
              onClick={() => navigation(menu.link)}
              key={`menu-${index}`}
            > {menu.title} </MenuItem>
          )}  
          
            <MenuItem
              icon={<Img src={LogoutIcon} alt="image_one" className="h-[20px] w-[20px]" />}
              onClick={() => logout()}
            > Logout </MenuItem>

          </Menu>
        </Sidebar>
        <div className="flex flex-col items-center justify-start w-full gap-[37px]">
          <Header className="flex justify-center items-center w-full sm:w-full p-5 bg-white-A700 shadow-xs" />
          <Outlet />
        </div>
      </div>
    </>
  );
}