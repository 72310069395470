/* eslint-disable no-unused-vars */
import { useEffect, useState } from "react";
import { SITE_NAME } from "Helper/constant"
import { Helmet } from "react-helmet";
import { Text, Button, Input, SelectBox,Img } from "../../components";
import { useNavigate, useParams } from "react-router-dom";
import { API_ROUTES } from "Helper/ApiRoutes";
import { toast } from "react-toastify";
import GlobalService from "Helper/GlobalService";
import encodeDecodeUri from 'Helper/crypto/encodeDecodeUri';
import ArrowDownIcon from "assets/images/img_arrowdown.svg"

const dropDownOptions = [
  { label: "Github", value: "github" },
  { label: "Gitlab", value: "gitlab" },
  { label: "Bit Bucket", value: "bit bucket" },
];

export default function AddGithubCredential() {

  const navigation = useNavigate();
  const { id } = useParams();
  const [type, setType] = useState({ label: "Github", value: "github" });
  const [ownerName, setOwnerName] = useState('');
  const [ownerEmail, setOwnerEmail] = useState('');
  const [ownerUsername, setOwnerUsername] = useState('');
  const [ownerPassword, setOwnerPassword] = useState('');
  const [ownerToken, setOwnerToken] = useState('');
  const [ownerNameError, setOwnerNameError] = useState('');
  const [ownerEmailError, setOwnerEmailError] = useState('');
  const [ownerUsernameError, setOwnerUsernameError] = useState('');
  const [ownerPasswordError, setOwnerPasswordError] = useState('');
  const [ownerTokenError, setOwnerTokenError] = useState('');

  const getDetail = async (id) => {
    const response = await GlobalService(API_ROUTES.GIT.GET_DETAILS(id));
    if (response.ack === 1) {
      setType(dropDownOptions.find((option) => option.value === response.data.type))
      setOwnerName(response.data.owner_name)
      setOwnerEmail(response.data.owner_email)
      setOwnerUsername(response.data.owner_username)
    }
  };

  const handleSave = async (e) => {
    e.preventDefault();
    let isValid = true;
    if (ownerName.trim() === '') {
      setOwnerNameError('Owner name is required.');
      isValid = false;
    }
    if (isValid) {
      const data = {
        type: type.value,
        owner_name: ownerName,
        owner_email: ownerEmail,
        owner_username: ownerUsername,
        owner_password: encodeDecodeUri(ownerPassword),
        owner_token: encodeDecodeUri(ownerToken)
      };
      const response = await GlobalService(API_ROUTES.GIT.ADD, data, 'post');
      if (response.ack === 1) {
        toast.success(response.message)
        navigation('/github-credentials')
      } else {
				if (response.errors) {
					for (const [key, value] of Object.entries(response.errors)) {
						toast.error(value[0])
					}
				} else {
					toast.error(response.message)
				}
			}
    }
  }

  const handleUpdate = async (e) => {
    e.preventDefault();
    let isValid = true;
    if (ownerName.trim() === '') {
      setOwnerNameError('Owner name is required.');
      isValid = false;
    }
    if (isValid) {
      let data = {
        type: type.value,
        owner_name: ownerName,
        owner_email: ownerEmail,
        owner_username: ownerUsername
      };
      if(ownerPassword.trim() !== '') {
        ownerPassword.owner_password = encodeDecodeUri(ownerPassword);
      }
      if(ownerToken.trim() !== '') {
        ownerPassword.owner_token = encodeDecodeUri(ownerToken);
      }
      const response = await GlobalService(API_ROUTES.GIT.UPDATE(id), data, 'put');
      if (response.ack === 1) {
        toast.success(response.message)
        navigation('/github-credentials')
      } else {
				if (response.errors) {
					for (const [key, value] of Object.entries(response.errors)) {
						toast.error(value[0])
					}
				} else {
					toast.error(response.message)
				}
			}
    }
  }

  useEffect(() => {
    getDetail(id);
  }, [id])

  return (
    <>
      <Helmet>
      <title>{SITE_NAME} | {id?'Update':'Add'} Github Credential</title>
        <meta name="description" content="Web site created using create-react-app" />
      </Helmet>
      <div className="flex flex-col items-start justify-start w-[94%] md:w-full gap-[31px]">
        <div className="flex justify-between gap-[7px] w-full">
          <Text size="xl" as="p">Github Credential</Text>
        </div>
          <form onSubmit={(e) => {
            if (id) {
              handleUpdate(e);
            } else {
              handleSave(e);
            }
          }} className="flex flex-row flex-col w-full gap-[30px]">
            <div className="flex flex-col items-start justify-center w-full gap-[26px] p-5 bg-white-A700 shadow-md rounded-[15px]">
              <Text size="lg" as="p" className="mt-1.5 !font-medium">
              {id?'Update':'Add'} Github Credential
              </Text>
              <div className="flex flex-col items-start justify-start w-full mb-1 gap-[25px]">
                <div className="flex flex-row justify-start w-full">
                  <div className="flex flex-col items-center justify-start w-full gap-[18px]">
                    
                    <div className="flex flex-row md:flex-col justify-start items-start w-full gap-[30px] md:gap-5">
                      <div className="flex flex-col items-start justify-start w-[49%] md:w-full gap-1.5">
                        <Text size="lg" as="p" className="!text-blue_gray-400">Type *</Text>
                        <SelectBox
                          indicator={<Img src={ArrowDownIcon} alt="arrow_down" />}
                          color="gray_50_01" size="sm" value={type} placeholder="Select" options={dropDownOptions}
                          onChange={(value) => {
                            setType(value)
                          }}
                          className="w-full gap-px border-gray-200 border border-solid rounded-[5px]"
                        />
                      </div>
                      <div className="flex flex-col items-start justify-start w-[49%] md:w-full gap-2">
                        <Text size="lg" as="p"> Owner Name *</Text>
                        <Input
                          color="gray_50_01" size="sm" type="text" name="name" placeholder="Owner Name *"
                          className="w-full sm:w-full border-gray-200 rounded-[5px]"
                          value={ownerName} onChange={(e) => {
                            setOwnerName(e.target.value);
                            setOwnerNameError('');
                          }}
                        />
                        <Text size="sm" as="p" className="error-text"> {ownerNameError} </Text>
                      </div>
                    </div>

                    <div className="flex flex-row md:flex-col justify-start w-full gap-[30px] md:gap-5">
                      <div className="flex flex-col items-start justify-start w-[49%] md:w-full gap-2">
                        <Text size="lg" as="p"> Owner Email *</Text>
                        <Input
                          color="gray_50_01" size="sm" type="email" name="name" placeholder="Owner Email *"
                          className="w-full sm:w-full border-gray-200 rounded-[5px]"
                          value={ownerEmail} onChange={(e) => {
                            setOwnerEmail(e.target.value);
                            setOwnerEmailError('');
                          }}
                        />
                        <Text size="sm" as="p" className="error-text"> {ownerEmailError} </Text>
                      </div>
                      <div className="flex flex-col items-start justify-start w-[49%] md:w-full gap-2">
                        <Text size="lg" as="p">Owner Username *</Text>
                        <Input
                          color="gray_50_01" size="sm" name="price" placeholder="Owner Username *"
                          className="w-full sm:w-full border-gray-200 rounded-[5px]"
                          value={ownerUsername} onChange={(e) => {
                            setOwnerUsername(e.target.value);
                            setOwnerUsernameError('');
                          }}
                        />
                        <Text size="sm" as="p" className="error-text"> {ownerUsernameError} </Text>
                      </div>
                    </div>

                    <div className="flex flex-row md:flex-col justify-start w-full gap-[30px] md:gap-5">
                      <div className="flex flex-col items-start justify-start w-[49%] md:w-full gap-2">
                        <Text size="lg" as="p"> Owner Password</Text>
                        <Input
                          color="gray_50_01" size="sm" type="password" name="name" placeholder="Owner Password"
                          className="w-full sm:w-full border-gray-200 rounded-[5px]"
                          value={ownerPassword} onChange={(e) => {
                            setOwnerPassword(e.target.value)
                            setOwnerPasswordError()
                          }}
                        />
                        <Text size="sm" as="p" className="error-text"> {ownerPasswordError} </Text>
                      </div>
                      <div className="flex flex-col items-start justify-start w-[49%] md:w-full gap-2">
                        <Text size="lg" as="p">Owner Token</Text>
                        <Input
                          color="gray_50_01" size="sm" name="price" placeholder="Owner Token"
                          className="w-full sm:w-full border-gray-200 rounded-[5px]"
                          value={ownerToken} onChange={(e) => {
                            setOwnerToken(e.target.value);
                            setOwnerTokenError('');
                          }}
                        />
                        <Text size="sm" as="p" className="error-text"> {ownerTokenError} </Text>
                      </div>
                    </div>

                  </div>
                </div>
                <div className="flex flex-row justify-start gap-[21px]">
                  <Button size="md" shape="round" className="sm:px-5 font-medium min-w-[112px]" type='submit'>
                    Submit
                  </Button>
                  <Button
                    size="md" variant="outline" shape="round" className="sm:px-5 font-medium min-w-[112px]" type='button'
                    onClick={() => navigation('/github-credentials')}
                  > Cancel </Button>
                </div>
              </div>
            </div>
        </form>
      </div>
    </>
  );
}
