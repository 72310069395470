import { Text, Button, Img } from "components";
import { Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import CloseIcon from "assets/images/closeIcon.svg"

export default function DeleteDialogs({ close, handleYes }) {
  return (
    <Dialog onClose={close} open={true} >
      <DialogTitle className="flex justify-between gap-[7px] w-full">
				<Text size="xl" as="p">Delete</Text>
				<Img 
					src={CloseIcon} alt="image_one" onClick={close} title="Close Model"
					className="h-[25px] w-[25px] pointer white-svg"
				/>
			</DialogTitle>
			<DialogContent className="flex flex-row flex-col p-[10px]  rounded-[10px]">
				<Text size="lg" as="p" className="!font-medium"> Are you sure you want to Delete this record.  </Text>
				<Text size="lg" as="p" className="!font-medium"> You will not be able to retrive it. </Text>
			</DialogContent>
			<DialogActions className="flex flex-row justify-start gap-[5px] p-20">
        <Button shape="round" className="font-medium" type='button' onClick={handleYes}> Yes </Button>
        <Button variant="outline" shape="round" className="font-medium" type='button' onClick={close}> No </Button>
      </DialogActions>
    </Dialog>
  );
}
