import { useState, useEffect } from "react";
import { SITE_NAME } from "Helper/constant"
import { Helmet } from "react-helmet";
import { Text, Heading, Img, Button } from "../../components";
import {API_ROUTES} from "Helper/ApiRoutes";
import GlobalService from "Helper/GlobalService";
import ProjectIcon from "assets/images/project-icon.svg"
import GithubIcon from "assets/images/github-icon.svg"
import ServerCredsIcon from "assets/images/server-creds-icon.svg"
import ServerIcon from "assets/images/server-icon.svg"

export default function DashboardPage() {

  const [countData, setCountData] = useState({
    project_count: 0,
    git_count: 0,
    server_command_count: 0,
    server_credential_count: 0
  });

  const getDashboardData = async () => {
    const response = await GlobalService(API_ROUTES.DASHBOARD.GETDATA)
    if (response.ack === 1) {
      setCountData(response.data)
    }
  };

  useEffect(() => {
    getDashboardData();
  }, [])

  return (
    <>
    <Helmet>
      <title>{SITE_NAME} | Dashboard</title>
      <meta name="description" content="Web site created using create-react-app" />
    </Helmet>
    <div className="flex flex-col items-start justify-start w-[94%] md:w-full gap-[31px]">
      <div className="flex flex-col items-start justify-start gap-[7px]">
        <Text size="xl" as="p"> Dashboard </Text>
      </div>

      <div className="flex flex-row md:flex-col w-full gap-[30px]">
        <div className="flex flex-row justify-start items-center w-[23%] md:w-full gap-6 p-[30px] sm:p-5 bg-white-A700 shadow-md rounded-[10px]">
          <Button color="blue_50" size="4xl" shape="circle" className="w-[70px]">
            <Img src={ProjectIcon} />
          </Button>
          <div className="flex flex-col items-start justify-start w-[40%] mr-[23px] gap-[7px] sm:mr-5">
            <Heading size="xl" as="h1"> {countData?.project_count} </Heading>
            <Text as="p" className="!text-blue_gray-400 !font-normal"> Total Projects </Text>
          </div>
        </div>
        <div className="flex flex-row justify-start items-center w-[23%] md:w-full gap-6 p-[30px] sm:p-5 bg-white-A700 shadow-md rounded-[10px]">
          <Button color="blue_50" size="4xl" shape="circle" className="w-[70px]">
            <Img src={GithubIcon} />
          </Button>
          <div className="flex flex-col items-start justify-start w-[47%] mr-2.5 gap-1.5">
            <Heading size="xl" as="h2"> {countData?.git_count} </Heading>
            <Text as="p" className="!text-blue_gray-400 !font-normal"> Total Github Credentials </Text>
          </div>
        </div>
        <div className="flex flex-row justify-start items-center w-[23%] md:w-full gap-6 p-[30px] sm:p-5 bg-white-A700 shadow-md rounded-[10px]">
          <Button color="blue_50" size="4xl" shape="circle" className="w-[70px]">
            <Img src={ServerCredsIcon} />
          </Button>
          <div className="flex flex-col items-start justify-start w-[50%] mr-[5px] gap-[7px]">
            <Heading size="xl" as="h3"> {countData?.server_credential_count} </Heading>
            <Text as="p" className="!text-blue_gray-400 !font-normal"> Total Server Credentials </Text>
          </div>
        </div>
        <div className="flex flex-row justify-start items-center w-[23%] md:w-full gap-6 p-[30px] sm:p-5 bg-white-A700 shadow-md rounded-[10px]">
          <Button color="blue_50" size="4xl" shape="circle" className="w-[70px]">
            <Img src={ServerIcon} />
          </Button>
          <div className="flex flex-col items-start justify-start w-[37%] gap-[7px]">
            <Heading size="xl" as="h4"> {countData?.server_command_count} </Heading>
            <Text as="p" className="!text-blue_gray-400 !font-normal"> Total Server Commands </Text>
          </div>
        </div>
      </div>

      <div className="flex flex-row md:flex-col justify-start w-full gap-[30px] md:gap-5">
        
        <div className="flex flex-col items-start w-[32%] md:w-full gap-[22px] p-5 bg-white-A700 shadow-md rounded-[15px]">
          <Text size="lg" as="p" className="mt-[5px] !font-medium">
            Server Command
          </Text>
          <div className="flex flex-col w-[97%] mb-2 gap-[11px] md:gap-[2.75px]">
            <div className="overflow-x-auto">
              <table className="w-full border-collapse border border-gray-200">
                <thead>
                  <tr>
                    <td className="px-4 py-2 bg-gray-50 border-b">Name</td>
                    <td className="px-4 py-2 bg-gray-50 border-b">Email</td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="px-4 py-2 border-b">John Doe</td>
                    <td className="px-4 py-2 border-b">john.doe@example.com</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="flex flex-col items-start w-[32%] md:w-full gap-[22px] p-5 bg-white-A700 shadow-md rounded-[15px]">
          <Text size="lg" as="p" className="mt-[5px] !font-medium">
            Github Credential
          </Text>
          <div className="flex flex-col w-[97%] mb-2 gap-[11px] md:gap-[2.75px]">
            <div className="overflow-x-auto">
              <table className="w-full border-collapse border border-gray-200">
                <thead>
                  <tr>
                    <td className="px-4 py-2 bg-gray-50 border-b">Name</td>
                    <td className="px-4 py-2 bg-gray-50 border-b">Email</td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="px-4 py-2 border-b">John Doe</td>
                    <td className="px-4 py-2 border-b">john.doe@example.com</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div className="flex flex-col items-start w-[32%] md:w-full gap-[22px] p-5 bg-white-A700 shadow-md rounded-[15px]">
          <Text size="lg" as="p" className="mt-[5px] !font-medium">
            Active Projects
          </Text>
          <div className="flex flex-col w-[97%] mb-2 gap-[11px] md:gap-[2.75px]">
            <div className="overflow-x-auto">
              <table className="w-full border-collapse border border-gray-200">
                <thead>
                  <tr>
                    <td className="px-4 py-2 bg-gray-50 border-b">Name</td>
                    <td className="px-4 py-2 bg-gray-50 border-b">Email</td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="px-4 py-2 border-b">John Doe</td>
                    <td className="px-4 py-2 border-b">john.doe@example.com</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
  );
}
