/* eslint-disable no-unused-vars */
import { useEffect, useState } from "react";
import { SITE_NAME } from "Helper/constant"
import { Helmet } from "react-helmet";
import { Text, Button, Input } from "../../components";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { API_ROUTES } from "Helper/ApiRoutes";
import GlobalService from "Helper/GlobalService";

export default function AddServerCommand() {

  const { id } = useParams();
  const navigation = useNavigate();
  const [commandTitle, setCommandTitle] = useState('');
  const [commandExample, setCommandExample] = useState('');
  const [command, setCommand] = useState('');
	const [commandTitleError, setCommandTitleError] = useState('');
  const [commandExampleError, setCommandExampleError] = useState('');
  const [commandError, setCommandError] = useState('');
   
	const getDetail = async (id) => {
    const response = await GlobalService(API_ROUTES.COMMAND.GET_DETAILS(id));
    if (response.ack === 1) {
			setCommandTitle(response.data.command_title);
			setCommandExample(response.data.command_example);
			setCommand(response.data.command);
    }
  };

  const handleSave = async (e) => {
    e.preventDefault();
    let isValid = true;
		if (commandTitle.trim() === '') {
			setCommandTitleError('Command title is required');
			isValid = false;
		}
		if (commandExample.trim() === '') {
			setCommandExampleError('Command example is required');
			isValid = false;
		}
		if (command.trim() === '') {
			setCommandError('Command is required');
			isValid = false;
		}
		if (isValid) {
			const data = {
				command_title: commandTitle,
				command_example: commandExample,
				command: command
			};
			const response = await GlobalService(API_ROUTES.COMMAND.ADD, data, 'post');
			if (response.ack === 1) {
				toast.success(response.message)
        navigation('/commands')
      } else {
				if (response.errors) {
					for (const [key, value] of Object.entries(response.errors)) {
						toast.error(value[0])
					}
				} else {
					if (response.errors) {
						for (const [key, value] of Object.entries(response.errors)) {
							toast.error(value[0])
						}
					} else {
						toast.error(response.message)
					}
				}
			}
		}
  }

  const handleUpdate = async (e) => {
    e.preventDefault();
    let isValid = true;
		if (commandTitle.trim() === '') {
			setCommandTitleError('Command title is required');
			isValid = false;
		}
		if (commandExample.trim() === '') {
			setCommandExampleError('Command example is required');
			isValid = false;
		}
		if (command.trim() === '') {
			setCommandError('Command is required');
			isValid = false;
		}
		if (isValid) {
			const data = {
				command_title: commandTitle,
				command_example: commandExample,
				command: command
			};
			const response = await GlobalService(API_ROUTES.COMMAND.UPDATE(id), data, 'put');
			if (response.ack === 1) {
				toast.success(response.message)
        navigation('/commands')
      } else {
				if (response.errors) {
					for (const [key, value] of Object.entries(response.errors)) {
						toast.error(value[0])
					}
				} else {
					toast.error(response.message)
				}
			}
		}
  }

  useEffect(() => {
		if (id) {
			getDetail(id);
		}
  }, [id])

  return (
    <>
      <Helmet>
      <title>{SITE_NAME} | {id?'Update':'Add'} Server Command</title>
        <meta name="description" content="Web site created using create-react-app" />
      </Helmet>
      <div className="flex flex-col items-start justify-start w-[94%] md:w-full gap-[31px]">
        <div className="flex justify-between gap-[7px] w-full">
          <Text size="xl" as="p">Server Command</Text>
        </div>
          <form onSubmit={(e) => {
            if (id) {
              handleUpdate(e);
            } else {
              handleSave(e);
            }
          }} className="flex flex-row flex-col w-full gap-[30px]">
            <div className="flex flex-col items-start justify-center w-full gap-[26px] p-5 bg-white-A700 shadow-md rounded-[15px]">
              <Text size="lg" as="p" className="mt-1.5 !font-medium">
							{id?'Update':'Add'} Server Command
              </Text>
              <div className="flex flex-col items-start justify-start w-full mb-1 gap-[25px]">
                <div className="flex flex-row justify-start w-full">
                  <div className="flex flex-col items-center justify-start w-full gap-[18px]">
                    <div className="flex flex-row md:flex-col justify-start items-start w-full gap-[30px] md:gap-5">
                      <div className="flex flex-col items-start justify-start w-full gap-2">
                        <Text size="lg" as="p"> Command Title *</Text>
                        <Input
                          color="gray_50_01" size="sm" type="text" name="name" placeholder="Command Title *"
                          className="w-full sm:w-full border-gray-200 rounded-[5px]"
                          value={commandTitle} onChange={(e) => {
														setCommandTitle(e.target.value);
														setCommandTitleError('');
													}}
                        />
                        <Text size="sm" as="p" className="error-text"> {commandTitleError} </Text>
                      </div>
                    </div>
										<div className="flex flex-row md:flex-col justify-start items-start w-full gap-[30px] md:gap-5">
                      <div className="flex flex-col items-start justify-start w-full gap-2">
                        <Text size="lg" as="p"> Command Example *</Text>
                        <Input
                          color="gray_50_01" size="sm" type="text" name="name" placeholder="Command Example *"
                          className="w-full sm:w-full border-gray-200 rounded-[5px]"
                          value={commandExample} onChange={(e) => {
														setCommandExample(e.target.value);
														setCommandExampleError('');
													}}
                        />
                        <Text size="sm" as="p" className="error-text"> {commandExampleError} </Text>
                      </div>
                    </div>
										<div className="flex flex-row md:flex-col justify-start items-start w-full gap-[30px] md:gap-5">
                      <div className="flex flex-col items-start justify-start w-full gap-2">
                        <Text size="lg" as="p"> Command *</Text>
                        <Input
                          color="gray_50_01" size="sm" type="text" name="name" placeholder="Command *"
                          className="w-full sm:w-full border-gray-200 rounded-[5px]"
                          value={command} onChange={(e) => {
														setCommand(e.target.value);
														setCommandError('');
													}}
                        />
                        <Text size="sm" as="p" className="error-text"> {commandError} </Text>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex flex-row justify-start gap-[21px]">
                  <Button size="md" shape="round" className="sm:px-5 font-medium min-w-[112px]" type='submit'>
                    Submit
                  </Button>
                  <Button
										size="md" variant="outline" shape="round" className="sm:px-5 font-medium min-w-[112px]" type='button'
										onClick={() => navigation('/commands')}
									> Cancel </Button>
                </div>
              </div>
            </div>
        </form>
      </div>
    </>
  );
}
