export const SITE_NAME = 'Project Doc';
export const SITE_ADDRESS = '102 Sakina Apartment Noorani Nagar, Dhar Road, Indore M.P. 452002, India';
export const SITE_EMAIL = 'info@dewaswala.com';
export const SITE_PHONE = '+91-62648 28215';
export const DEFAULT_LANGUAGE = 'en';
export const DEFAULT_COUNTRY = 'IN';
export const languages = ['en', 'hi'];
// export const BASE_URL = 'https://api.dewaswala.com';
export const BASE_URL = 'http://localhost:8000/api';
export const API_KEY = 'HiIrxuIhBWbupnlSLLgBODrodByiaK';
export const GOOGLE_RECAPTCHA_SITE_KEY = '6Lc2Xf4UAAAAAABzv3';