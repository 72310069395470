import React from "react";
import { SITE_NAME } from "Helper/constant"
import { Helmet } from "react-helmet";
import { Text } from "../../components";

export default function Project() {
  return (
    <>
      <Helmet>
      <title>{SITE_NAME} | Project</title>
        <meta name="description" content="Web site created using create-react-app" />
      </Helmet>
      <div className="flex flex-col items-start justify-start w-[94%] md:w-full gap-[31px]">
        <div className="flex flex-col items-start justify-start gap-[7px]">
          <Text size="xl" as="p"> Project </Text>
        </div>

        <div className="flex flex-row flex-col w-full gap-[30px]">
          <div className="flex flex-row justify-start items-center w-full gap-6 p-[30px] sm:p-5 bg-white-A700 shadow-md rounded-[10px]">
            <div className="overflow-x-auto">
              <table className="w-full border-collapse border border-gray-200">
                <thead>
                  <tr>
                    <td className="px-4 py-2 bg-gray-50 border-b">Name</td>
                    <td className="px-4 py-2 bg-gray-50 border-b">Email</td>
                    <td className="px-4 py-2 bg-gray-50 border-b">Role</td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="px-4 py-2 border-b">John Doe</td>
                    <td className="px-4 py-2 border-b">john.doe@example.com</td>
                    <td className="px-4 py-2 border-b">Admin</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
