/* eslint-disable no-unused-vars */
import { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import { SITE_NAME } from "Helper/constant"
import { Helmet } from "react-helmet";
import { Text, Button, Img } from "../../components";
import { API_ROUTES } from "Helper/ApiRoutes";
import { toast } from "react-toastify";
import DeleteDialogs from "Dialogs/DeleteDialogs"
import GlobalService from "Helper/GlobalService";
import encodeDecodeUri from 'Helper/crypto/encodeDecodeUri';
import CopyIcon from "assets/images/copy.svg"
import CopyWhiteIcon from "assets/images/copy-white.svg"

export default function GithubCredential() {

  const navigation = useNavigate();
  const [gitCreds, setGitCreds] = useState([]);
  const [openDelete, setOpenDelete] = useState(false)
  const [deleteId, setDeleteId] = useState(null)

  const getGitCreds = async () => {
    const response = await GlobalService(API_ROUTES.GIT.GIT_LIST);
    setGitCreds(response.data)
  };

  const handleDelete = async () => {
    setOpenDelete(false);
    const response = await GlobalService(API_ROUTES.GIT.DELETE(deleteId), null, 'delete');
    if (response.ack === 1) {
      toast.success(response.message)
      setDeleteId(null);
      getGitCreds();
    } else {
      if (response.errors) {
        for (const [key, value] of Object.entries(response.errors)) {
          toast.error(value[0])
        }
      } else {
        toast.error(response.message)
      }
    }
  }; 

  const copyText = async (text) => {
    navigator.clipboard.writeText(text)
  }

  useEffect(() => {
    getGitCreds();
  }, [])

  return (
    <>
      <Helmet>
      <title>{SITE_NAME} | Github Credential</title>
        <meta name="description" content="Web site created using create-react-app" />
      </Helmet>
      <div className="flex flex-col items-start justify-start w-[94%] md:w-full gap-[31px]">
        <div className="flex justify-between gap-[7px] w-full">
          <Text size="xl" as="p">Github Credential</Text>
          <Button
            size="md" shape="round" type="button" className="sm:px-5 font-medium min-w-[112px]"
            onClick={() => navigation('/add-github-credential')}
          >Add</Button>
        </div>
        <div className="flex flex-row flex-col w-full gap-[30px]">
          <div className="flex flex-row justify-start items-center w-full gap-6 p-[30px] sm:p-5 bg-white-A700 shadow-md rounded-[10px]">
            <div className="overflow-x-auto">
              <table className="w-full border-collapse border border-gray-200">
                <thead>
                  <tr>
                    <td className="px-4 py-2 bg-gray-50 border-b">Type</td>
                    <td className="px-4 py-2 bg-gray-50 border-b">Name</td>
                    <td className="px-4 py-2 bg-gray-50 border-b">Email</td>
                    <td className="px-4 py-2 bg-gray-50 border-b">Username</td>
                    <td className="px-4 py-2 bg-gray-50 border-b">Copy</td>
                    <td className="px-4 py-2 bg-gray-50 border-b">Action</td>
                  </tr>
                </thead>
                <tbody>
                  {gitCreds?.map((gitCred, index) =>
                  <tr key={`git-credentials-${index}`}>
                    <td className="px-4 py-2 border-b"> <Text>{gitCred?.type}</Text> </td>
                    <td className="px-4 py-2 border-b"> <Text>{gitCred?.owner_name}</Text> </td>
                    <td className="px-4 py-2 border-b">
                      <Text className="flex flex-start">
                        <Img
                          src={CopyIcon} alt="image_one" className="h-[15px] w-[15px] pointer" title="Copy Email"
                          onClick={() => copyText(gitCred?.owner_email)}
                        />
                        {gitCred?.owner_email}
                      </Text>
                    </td>
                    <td className="px-4 py-2 border-b">
                      <Text className="flex flex-start">
                        <Img
                          src={CopyIcon} alt="image_one" className="h-[15px] w-[15px] pointer" title="Copy Username"
                          onClick={() => copyText(gitCred?.owner_username)}
                        />
                        {gitCred?.owner_username}
                      </Text>
                    </td>
                    <td className="px-4 py-2 border-b flex flex-start">
                      <Button
                        size="md" shape="round" type="button" className="sm:px-5 font-medium"
                        leftIcon={<Img src={CopyWhiteIcon} alt="image_one" className="h-[25px] w-[25px] pointer white-svg" title="Copy Username" />}
                        onClick={() => copyText(encodeDecodeUri(gitCred?.owner_password, 'de')) }
                      >Password</Button>
                      <Button
                        size="md" shape="round" type="button" className="sm:px-5 font-medium ml-4"
                        leftIcon={<Img src={CopyWhiteIcon} alt="image_one" className="h-[25px] w-[25px] pointer white-svg" title="Copy Username" />}
                        onClick={() => copyText(encodeDecodeUri(gitCred?.owner_token, 'de')) }
                      >Token</Button>
                    </td>
                    <td className="px-4 py-2 border-b">
                      <span className=" flex flex-start">
                        <Link className="ml-4 inline-flex items-center rounded-md bg-blue-50 px-2 py-1 text-xs font-medium text-blue-700 ring-1 ring-inset ring-blue-700/10" to={`/edit-github-credential/${gitCred?.id}`} >Edit</Link>
                        <span
                          className="ml-4 inline-flex items-center rounded-md bg-blue-50 px-2 py-1 text-xs font-medium text-blue-700 ring-1 ring-inset ring-blue-700/10 pointer"
                          onClick={() => {
                            setDeleteId(gitCred?.id)
                            setOpenDelete(true)
                          }}
                        >Delete</span>
                      </span>
                    </td>
                  </tr>)}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      {openDelete ?
        <DeleteDialogs
          close={() => setOpenDelete(false) }
          handleYes={handleDelete}
        />
        :null}
    </>
  );
}
