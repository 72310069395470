import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { SITE_NAME } from "Helper/constant"
import { Helmet } from "react-helmet";
import { Text, Input, Button } from "../../components";
import { getLocalStorage, setLocalStorage, LOCAL_STORAGE_KEYS } from 'Helper/crypto/LocalStorage';
import GlobalService from "Helper/GlobalService";
import {API_ROUTES} from "Helper/ApiRoutes";

export default function Login() {

  const navigation = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    let isValid = true;
    if (email.trim() === '') {
      setEmailError('Email is required');
      isValid = false;
    }
    if (password.trim() === '') {
      setPasswordError('Password is required');
      isValid = false;
    }
    if (isValid) {
      const data = {
        identity: email,
        password: password
      };
      const response = await GlobalService(API_ROUTES.AUTH.LOGIN, data, 'post');
      if (response.ack === 1) {
        setLocalStorage(LOCAL_STORAGE_KEYS.AUTH_TOKEN, response.data.token);
        setLocalStorage(LOCAL_STORAGE_KEYS.USER_DATA, response.data.user);
        navigation('/');
      }
    }
  }

  useEffect(() => {
    if (getLocalStorage(LOCAL_STORAGE_KEYS.AUTH_TOKEN)) {
      navigation('/');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
    <Helmet>
      <title>{SITE_NAME} | Login</title>
      <meta name="description" content="Web site created using create-react-app" />
    </Helmet>
    <form onSubmit={handleSubmit}>
      <div className="flex flex-col items-center justify-center w-full gap-[31px] h-full">
        <div className="flex flex-col mb-2 gap-[11px]">
          <div className="flex flex-col items-start justify-center w-[500px] gap-[26px] p-5 bg-white-A700 shadow-md rounded-[15px]">
            <Text size="lg" as="h1" className="mt-1.5 font-large center-text w-full">Login</Text>
            <hr />
            <div className="flex flex-col items-center justify-start w-full mb-1 gap-[25px]">
              <div className="flex flex-row justify-start w-full">
                <div className="flex flex-col items-center justify-start w-full gap-[18px]">
                  <div className="w-full gap-[30px] md:gap-5">
                    <div className="flex flex-col w-full gap-2">
                      <Text size="lg" as="p"> Email </Text>
                      <Input
                        color="gray_50_01" size="sm" type="email" placeholder="Enter Email"
                        className="w-full border-gray-200 rounded-[5px]"
                        value={email} onChange={(e) => { setEmail(e.target.value); setEmailError(''); }}
                      />
                      <Text size="sm" as="p" className="error-text"> {emailError} </Text>
                    </div>
                    <div className="flex flex-col w-full gap-2">
                      <Text size="lg" as="p"> Password </Text>
                      <Input
                        color="gray_50_01" size="sm" type="password" placeholder="Enter Password"
                        className="w-full border-gray-200 rounded-[5px]"
                        value={password} onChange={(e) => { setPassword(e.target.value); setPasswordError(''); }}
                      />
                      <Text size="sm" as="p" className="error-text"> {passwordError} </Text>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex flex-row justify-start gap-[21px]">
                <Button size="md" shape="round" type="submit" className="sm:px-5 font-medium min-w-[112px]">Login</Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
    </>
  );
}
