import encryptDcrypt from './encryptDcrypt';

/**
 * @file encodeDecodeUri.js
 * @description This function is used to encode and decode the uri
 * @param {*} value 
 * @param {*} type
 * @default en
 * @returns encoded or decoded uri
 */
const encodeDecodeUri = (value, type = "en") => {
  if (value !== undefined) {
    if (type === 'en') {
      return encodeURIComponent(encryptDcrypt(value, type), type);
    } else {
      return encryptDcrypt(decodeURIComponent(value, type), type);
    }
  }
}

export default encodeDecodeUri;