import React from "react";
import { useRoutes } from "react-router-dom";
import AppLayout from "./Layout/AppLayout";
import Login from "./Auth/Login"
import NotFound from "pages/NotFound";
import Dashboard from "pages/Dashboard";
import Project from "pages/Project";

import GithubCredential from "pages/GithubCredential";
import AddGithubCredential from "pages/GithubCredential/AddGithubCredential";

import ServerCommand from "pages/ServerCommand";
import AddServerCommand from "pages/ServerCommand/AddServerCommand";

import ServerCredential from "pages/ServerCredential";
import AddServerCredential from "pages/ServerCredential/AddServerCredential"

const ProjectRoutes = () => {
  let element = useRoutes([
    { path: "*", element: <NotFound /> },
    { path: "login", element: <Login /> },
    {
      path: "/",
      element: <AppLayout />,
      children: [
        {
          path: "/",
          element: <Dashboard />,
        },
        {
          path: "project",
          element: <Project />,
        },
        {
          path: "github-credentials",
          element: <GithubCredential />
        },
        {
          path: "add-github-credential",
          element: <AddGithubCredential />,
        },
        {
          path: "edit-github-credential/:id",
          element: <AddGithubCredential />,
        },
        {
          path: "commands",
          element: <ServerCommand />,
        },
        {
          path: "add-command",
          element: <AddServerCommand />,
        },
        {
          path: "edit-command/:id",
          element: <AddServerCommand />,
        },
        {
          path: "server-credential",
          element: <ServerCredential />,
        },
        {
          path: "add-server-credential",
          element: <AddServerCredential />,
        },
        {
          path: "edit-server-credential/:id",
          element: <AddServerCredential />,
        }
        
      ]
    },
  ]);

  return element;
};

export default ProjectRoutes;
