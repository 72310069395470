import Axios from "axios";
import { BASE_URL, API_KEY } from "./constant";
import {
	getLocalStorage,
	// setLocalStorage,
	LOCAL_STORAGE_KEYS
} from './crypto/LocalStorage';
import errorSwitch from "./serviceError";

// const refreshAuthToken = async () => {
// 	if (getLocalStorage(LOCAL_STORAGE_KEYS.AUTH_TOKEN)) {
// 		const token = getLocalStorage(LOCAL_STORAGE_KEYS.AUTH_TOKEN);
// 		const timeLeft = 0
// 		if (timeLeft < 60) {
// 			await Axios({
// 				method: 'get',
// 				url: BASE_URL + '/refresh',
// 				data: null,
// 				headers: {
// 					"x-api-key": API_KEY,
// 					"Authorization": (getLocalStorage(LOCAL_STORAGE_KEYS.AUTH_TOKEN)) ?
// 						"Bearer " + getLocalStorage(LOCAL_STORAGE_KEYS.AUTH_TOKEN) :
// 						null,
// 					"accept":"application/json",
// 					"Content-Type":"application/json",
// 					'Access-Control-Allow-Origin': "*",
// 					'Access-Control-Allow-Methods':'GET, POST, PUT, DELETE, OPTIONS'
// 				}
// 			}).then((response) => {
// 				if (response.ack === 1) {
// 					setLocalStorage(LOCAL_STORAGE_KEYS.AUTH_TOKEN, response.data);
// 				}
// 			}).catch((error) => {
// 				errorSwitch(error);
// 			});
// 		}
// 	}
// }

const GlobalService = async (url, data = {}, type = "get") => {
  return new Promise(async (resolve, reject) => {
		if (getLocalStorage(LOCAL_STORAGE_KEYS.AUTH_TOKEN)) {
			// await refreshAuthToken();
		}
    await Axios({
      method: type,
      url: BASE_URL + url,
      data: type !== "get" ? data : null,
      headers: {
        "x-api-key": API_KEY,
        "Authorization": (getLocalStorage(LOCAL_STORAGE_KEYS.AUTH_TOKEN)) ?
					"Bearer " + getLocalStorage(LOCAL_STORAGE_KEYS.AUTH_TOKEN) :
					null,
				"accept":"application/json",
				"Content-Type":"application/json"
      },
      params: type === "get" ? { params: data } : null,
    }).then((response) => {
      resolve({
				ack: 1,
				message: response?.data?.message,
				data: response?.data?.data || null,
			});
    }).catch((error) => {
			resolve(errorSwitch(error));
    });
  });
};

export default GlobalService;
