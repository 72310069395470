export const API_ROUTES = {
	AUTH: {
		LOGIN: '/login',
		LOGOUT: '/logout'
	},
	DASHBOARD: {
		GETDATA: '/dashboard'
	},
	GIT: {
		ADD: '/add-git',
		GIT_LIST: '/git-list',
		GET_DETAILS: (id) => `/git/${id}`,
		UPDATE: (id) => `/update-git/${id}`,
		DELETE: (id) => `/git/${id}`
	},
	COMMAND: {
		ADD: '/add-command',
		GIT_LIST: '/command-list',
		GET_DETAILS: (id) => `/command/${id}`,
		UPDATE: (id) => `/update-command/${id}`,
		DELETE: (id) => `/command/${id}`
	},
	SERVER: {
		ADD: '/add-server',
		GIT_LIST: '/server-list',
		GET_DETAILS: (id) => `/server/${id}`,
		UPDATE: (id) => `/update-server/${id}`,
		DELETE: (id) => `/server/${id}`
	}
};