const sessionLogout = async (message) => {
  localStorage.clear();
  sessionStorage.clear();
  sessionStorage.setItem('error_message', message)
  window.location.href = '/';
}

const errorSwitch = (error) => {
	switch (error?.response?.status) {
		case 401:
			sessionLogout(error?.response?.data?.message)
			break;
		case 403:
			return {
				ack: 0,
				message: error?.response?.data?.message,
				errors: error?.response?.data?.errors || []
			};
		case 404:
			return {
				ack: 0,
				message: error?.response?.data?.message,
				errors: error?.response?.data?.errors || []
			}
		case 500:
			return {
				ack: 0,
				message: error?.response?.data?.message,
				errors: error?.response?.data?.errors || []
			}
		default:
			return {
				ack: 0,
				message: error?.response?.data?.message,
				errors: error?.response?.data?.errors || []
			}
	}
};

export default errorSwitch;