import React from "react";
// import { Img } from "./..";
// import personImage from "assets/images/img_ellipse_1.png"

export default function Header({ ...props }) {

  return (
    <header {...props}>
      <div className="flex flex-row sm:flex-col justify-between items-center w-full mx-auto md:px-5 sm:gap-10">
        <div></div>
        <div className="flex flex-row justify-between items-center w-auto">
          
        </div>
      </div>
    </header>
  );
}
